import { useEffect } from 'react';

const useWOW = () => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
        // import wow and init
        import('wowjs').then(({ WOW }) => {
            new WOW({
                live: false,
            }).init();
        });
    }
  }, []);
};

export default useWOW;
